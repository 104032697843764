import React from 'react';
import protractor from './resources/protractor.png';
import './Dota2protractor.css';

const Dota2protractor = () => {
    return (
        <div className="protractor-container">
            <h2>dota2protractor.com</h2>
            <div className="section">
                <div className="image-container">
                    <img src={protractor} alt="Protractor" />
                </div>
                <div className="text-container">
                    <div className="text-box">
                        <p>Using the Open Dota API, I created my first ever web app.  After entering a Steam ID, a user was able to see various details about that users most recent match of Dota 2, as depicted in the image.</p>
                        <p>I used Angular for the front end, and even created a backend to support the login feature.  When logged in, a user was able to save specific match data and view it at any time.</p>
                    </div>
                </div>
            </div>
            <div className='custom-hr'></div>
            <div className="section">
            <div className="text-container">
                    <div className="text-box">
                        <h2>What I learned</h2>
                        <p>This was my launchpad into fullstack development.  I found Angular to be less intuitive to React, and using react for ryoncook.dev really solidified my preference.  Implementing Auth0 for login really taught me a
                            lot about reinventing the wheel, something that my professional career has only continued to teach me.
                        </p>
                        <p>If I could reacreate this application over again from scratch, I would not implement a backend, at least not the way I implemented it.  I would start at the drawingboard- literally.  My biggest mistake with this application
                            was diving head first into development without so much as a plan for layout or user experience.  I am proud with what I ended up creating, but the potential of this webapp is much higher than was realized.
                        </p>
                        <p>Unfortunately, my passion for Dota 2 has waned as I get older, and althought it continues to hold a special place in my heart, I don't see myself ever resuming development on this project.  It remains as a testament
                            to my growth as a developer, and I will forever cherish it as my first real project.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dota2protractor;