import React from 'react';
import './RiscVSimulator.css';
import single_hart from './resources/single_hart.png';
import memory from './resources/memory.png';
import regfile from './resources/regfile.png';
import hex from './resources/hex.png'
import main from './resources/main.png'
import rv32i_decode from './resources/rv32i_decode.png'
import rv32i_hart from './resources/rv32i_hart.png'

const RiscVSimulator = () => {
    return (
        <div className="riscv-simulator-container">
            <div className="section">
                <div className="image-container">
                    <img src={main} alt="Main.cpp Overview" />
                </div>
                <div className="text-container">
                    <div className="text-box">
                        <h2>Main.cpp</h2>
                        <p>
                            The `main.cpp` file serves as the entry point of the RISC-V Simulator. It handles command-line arguments, sets up the simulation environment, and coordinates the simulation process. It includes:
                        </p>
                        <p>
                            Command-Line Options Handling: Parses options for disassembly, instruction printing, execution limits, and memory size.
                        </p>
                        <p>
                            Memory Initialization: Loads the program file into simulated memory.
                        </p>
                        <p>
                            CPU Setup: Initializes and configures the CPU with options for instruction and register display.
                        </p>
                        <p>
                            Simulation Execution: Runs the simulation based on the specified limits and options, and optionally dumps the state of the CPU and memory after execution.
                        </p>
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="image-container">
                    <img src={single_hart} alt="CpuSingleHart Overview" />
                </div>
                <div className="text-container">
                    <div className="text-box">
                        <h2>CpuSingleHart.cpp</h2>
                        <p>
                            The `CpuSingleHart.cpp` file defines the implementation of the single-hart CPU model. Key components include:
                        </p>
                        <p>
                            CPU State Management: Manages the state of the CPU, including registers and program counter.
                        </p>
                        <p>
                            Instruction Execution: Fetches, decodes, and executes instructions from memory.
                        </p>
                        <p>
                            Debugging Support: Provides functionality to display instruction execution and register states.
                        </p>
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="image-container">
                    <img src={memory} alt="Memory Overview" />
                </div>
                <div className="text-container">
                    <div className="text-box">
                        <h2>Memory.cpp</h2>
                        <p>
                            The `Memory.cpp` file handles memory operations and management. It includes:
                        </p>
                        <p>
                            Memory Allocation: Manages allocation and access to the simulated memory space.
                        </p>
                        <p>
                            File Loading: Supports loading program data from files into memory.
                        </p>
                        <p>
                            Memory Dumping: Provides functionality to dump memory contents for debugging.
                        </p>
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="image-container">
                    <img src={regfile} alt="RegisterFile Overview" />
                </div>
                <div className="text-container">
                    <div className="text-box">
                        <h2>RegisterFile.cpp</h2>
                        <p>
                            The `RegisterFile.cpp` file manages the CPU's registers. It includes:
                        </p>
                        <p>
                            Register Access: Functions to read and write register values.
                        </p>
                        <p>
                            Register Initialization: Sets initial values and manages register states during simulation.
                        </p>
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="image-container">
                    <img src={rv32i_decode} alt="Rv32iDecode Overview" />
                </div>
                <div className="text-container">
                    <div className="text-box">
                        <h2>Rv32iDecode.cpp</h2>
                        <p>
                            The `Rv32iDecode.cpp` file is responsible for decoding RISC-V instructions. Key functionalities include:
                        </p>
                        <p>
                            Instruction Decoding: Translates binary instructions into human-readable assembly format.
                        </p>
                        <p>
                            Instruction Handling: Supports various RISC-V instruction formats and operations.
                        </p>
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="image-container">
                    <img src={rv32i_hart} alt="Rv32iHart Overview" />
                </div>
                <div className="text-container">
                    <div className="text-box">
                        <h2>Rv32iHart.cpp</h2>
                        <p>
                            The `Rv32iHart.cpp` file defines the RISC-V hart (hardware thread) model. It includes:
                        </p>
                        <p>
                            Hart State Management: Manages the execution state of a single hart, including registers and program counter.
                        </p>
                        <p>
                            Instruction Execution: Executes instructions specific to the hart's configuration and state.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RiscVSimulator;