import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'; // Import the global CSS file

const About = () => {
  return (
    <div className="container my-5">
      <div className="text-box">
        <h2 className="text-center">About Me</h2>
        <p className="text-center">
          I'm a passionate software developer based in Chicago, IL. I specialize in Java Spring Boot and have significant experience in developing and maintaining enterprise-level applications. My work involves creating robust, scalable solutions and collaborating with cross-functional teams to drive projects to success.
        </p>
        <p className="text-center">
          With a background in Computer Science from Northern Illinois University and experience at Zebra Technologies and Cognizant, I have developed a strong skill set in both backend and frontend technologies. I have worked on various projects, from developing core applications for global customers to enhancing cloud infrastructure and optimizing application performance.
        </p>
        <p className="text-center">
          Checkout more about me:
        </p>
        <div className="d-flex justify-content-center">
          <a href="https://github.com/ryon137" target="_blank" rel="noopener noreferrer" className="text-light mx-3">
            <img 
              src="/github.png" 
              alt="GitHub" 
              style={{ width: '48px', height: '48px' }} 
            />
          </a>
          <a href="https://www.linkedin.com/in/ryon-cook/" target="_blank" rel="noopener noreferrer" className="text-light mx-3">
            <img 
              src="/linkedin.png" 
              alt="LinkedIn" 
              style={{ width: '48px', height: '48px' }} 
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default About;