import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

const Contact = () => {
  return (
    <div className="container my-5">
      <div className="text-box">
        <h2 className="text-center">Contact Me</h2>
        <p className="text-center">
          I am always open to discussing new projects, creative ideas, or opportunities to be part of your vision. 
          For serious inquiries, please feel free to reach out to me through the following channels:
        </p>
        <div className="d-flex justify-content-center mb-4">
          {/* Email Section */}
          <a href="mailto:ryon137@gmail.com" className="d-flex flex-column align-items-center text-light mx-2" style={{ textDecoration: 'none' }}>
            <span style={{ marginBottom: '8px' }}>Email:</span>
            <img 
              src="gmail.png" 
              alt="Email Logo" 
              style={{ width: '48px', height: '48px' }} 
            />
          </a>

          {/* LinkedIn Section */}
          <a href="https://www.linkedin.com/in/ryon-cook/" target="_blank" rel="noopener noreferrer" className="d-flex flex-column align-items-center text-light mx-2" style={{ textDecoration: 'none' }}>
            <span style={{ marginBottom: '8px' }}>LinkedIn:</span>
            <img 
              src="linkedin.png" 
              alt="LinkedIn Logo" 
              style={{ width: '48px', height: '48px' }} 
            />
          </a>
        </div>
        <p className="text-center mt-4">
          I look forward to connecting with you!
        </p>
      </div>
    </div>
  );
}

export default Contact;