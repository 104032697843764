import React from 'react';
import riscv from './resources/main.png';
import protractor from './resources/protractor.png'
import './Portfolio.css';

const Portfolio = () => {
  return (
    <div className="portfolio-container">
      <h1>My Projects</h1>
      
      {/* Project 1: RISC-V Simulator */}
      <div className="project" id="project1">
        <div className='section'>
          <div className="image-container">
            <img src={riscv} alt="Riscv" />
          </div>
          <div className='text-container'>
            <div className='text-box'>
              <h2>RISC-V Simulator</h2>
              <div className="project-description">
                <p>
                  The RISC-V Simulator is a 32-bit processor simulator built using C++. It allows users to learn the fundamentals of how a RISC-V CPU processes instructions, interacts with memory, and manages registers. The simulator decodes RISC-V instructions, simulates memory, and provides a step-by-step execution feature for users to observe how the CPU operates.
                </p>
                <p>
                  <a href="/projects/riscv-simulator" className="project-link">
                    Full writeup
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='custom-hr'></div>

      {/* Project 2: dota2protractor */}
      <div className="project" id="project1">
        <div className='section'>
          <div className='image-container'>
            <img src={protractor} alt="protractor" />
          </div>
          <div className='text-container'>
            <div className='text-box'>
              <h2>dota2protractor</h2>
              <div className="project-description">
                <p>
                  dota2protractor was built using Angular and Java Springboot, hosted in AWS.  It implemented Auth0 for authentication and used the Open Dota API to fetch match data on a given Dota 2 player.
                </p>
                <p>
                  <a href="/projects/dota2protractor" className="project-link">
                    Full writeup
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add more projects in similar text boxes */}
    </div>
  );
};

export default Portfolio;