import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Home.css'; // Import the custom CSS file

const Home = () => {
  return (
    <div className="container my-5">
      <div className="row align-items-center">
        {/* Image Section */}
        <div className="col-md-4 d-flex justify-content-center mb-4 mb-md-0">
          <img 
            src="headshot.jpg" 
            alt="Ryon Cook" 
            className="img-fluid rounded-circle" 
            style={{ maxWidth: '100%', height: 'auto' }} 
          />
        </div>

        {/* Text Section */}
        <div className="col-md-8">
          <div className="text-box">
            <h2>Welcome!</h2>
            <p>
              Hi, I'm Ryon Cook, a passionate software developer with a strong background in building scalable and efficient applications. I specialize in Java Spring Boot, which allows me to develop robust backend services and APIs. Additionally, I have experience with React.js and Bootstrap, showcasing my ability to handle both front-end and back-end projects from conception to deployment.
            </p>
            <p>
              My recent projects include developing dynamic web applications, optimizing performance, and integrating APIs to enhance functionality. I am also proficient in version control systems like Git and have experience working with agile methodologies to deliver high-quality software solutions on time.
            </p>
            <p>
              If you're interested in discussing potential projects or opportunities, feel free to reach out. I'm excited to connect and explore how we can create something amazing together!
            </p>
          </div>
        </div>
      </div>

      {/* Border and Centered Text Section */}
      <div className="my-5">
        <hr className="custom-hr" />
        <div className="row">
          <div className="col-md-12 d-flex justify-content-center mt-5">
            <div className="text-box">
              <h2>So, what is this place?</h2>
              <p>
                This website is my virtual landing page. Whether you are here to explore my websites and projects, hire me for a job, purchase something from my business (stay tuned!), or get in contact with me for some other reason, this is the place to do it.
              </p>
              <p>
                Go ahead and explore! Check out my <Link to="/projects/riscv-simulator">RiscVSimulator</Link>, or my <Link to="/projects/dota2protractor">dota2protractor</Link> webapp!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;